import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Stack, Typography } from "@mui/material";
import { ReactComponent as Logo } from "./logo.svg";
import { config } from "./config/config";
import { convertLongDate } from "./libs/utils";
import { AnimatedText } from "./components/AnimatedText";
import { Box, Button, Chip, Grid } from "@mui/joy";

const root = ReactDOM.createRoot(document.getElementById("root"));

function App() {
  const [displayButton, setDisplayButton] = useState(false);

  return (
    <Grid container maxWidth="sm" style={{ margin: "auto" }}>
      <Grid xs={12}>
        {/* <Typography variant="body1" color="#406fe6" sx={{ pb: 5 }}>
          Au service de l'innovation digitale
        </Typography> */}
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ maxHeight: "95vh", minHeight: "95vh", px: 2 }}
        >
          <Logo />
          <Box sx={{ px: 2 }}>
            <AnimatedText
              text={
                "Imaginez une entreprise pour laquelle seule la qualité des projets compte. Une entreprise qui met tout en œuvre pour garantir que chaque projet livré est d'une qualité exceptionnelle. Imaginez une entreprise où chaque membre de l'équipe est passionné par cette vision et où tout le monde travaille ensemble pour atteindre cet objectif commun.\n\n" +
                "Chez Neodigit, c'est exactement ce que nous offrons. Nous croyons que la qualité est la pierre angulaire de tout projet réussi et nous sommes déterminés à fournir les meilleurs résultats possibles pour nos clients. Nous investissons dans les meilleurs outils, technologies et processus pour garantir que chaque projet est soumis à des critères de qualité stricts.\n\n" +
                "En choisissant de travailler avec notre entreprise, vous pouvez avoir la certitude que vous obtiendrez des résultats exceptionnels. Nous sommes fiers de notre réputation pour la qualité de nos projets et nous continuerons à travailler dur pour la maintenir à l'avenir.\n\n" +
                "Alors, imaginez travailler avec une entreprise qui se soucie réellement de la qualité de ses projets et qui est déterminée à fournir les meilleurs résultats possibles pour ses clients. Vous pouvez arrêter d'imaginer maintenant, car nous sommes là pour vous offrir cette expérience de qualité.\n\n"
              }
              delay={30}
              callback={() => setDisplayButton(true)}
            ></AnimatedText>
            {displayButton && (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                {" "}
                <Chip variant="soft" sx={{ mx: 1 }}>
                  Développement
                </Chip>
                <Chip variant="soft" sx={{ mx: 1 }}>
                  Formation
                </Chip>
                <Chip variant="soft" sx={{ mx: 1 }}>
                  Conseil
                </Chip>
              </Stack>
            )}
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
