import { useEffect, useState } from "react";
import { Typography } from "@mui/joy";

export const AnimatedText = ({ text, delay, callback }) => {
  const [currentText, setCurrentText] = useState("");
  const [isCursorVisible, setIsCursorVisible] = useState(true);
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    let currentIndex = 0;

    const interval = setInterval(() => {
      if (currentIndex < text.length) {
        const currentChar = text[currentIndex];
        if (currentChar === "\n") {
          setCurrentText((prevText) => prevText + currentChar + "<br>");
        } else {
          setCurrentText((prevText) => prevText + currentChar);
        }
        currentIndex++;
      } else {
        clearInterval(interval);
        setIsDone(true);
        callback();
      }
    }, delay);

    return () => clearInterval(interval);
  }, [text, delay]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsCursorVisible((prevIsVisible) => !prevIsVisible);
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Typography sx={{ color: "black" }} level="body2">
      <span dangerouslySetInnerHTML={{ __html: currentText }}></span>
      <span
        style={{
          visibility: isCursorVisible && !isDone ? "visible" : "hidden",
        }}
      >
        |
      </span>
    </Typography>
  );
};
